import React, { createContext, useContext, useState } from "react"
import { v4 as uuidV4 } from "uuid"
import useMongo from "../hooks/useMongo"

const BudgetsContext = createContext()

export const UNCATEGORIZED_BUDGET_ID = "Uncategorized"

export function useBudgets() {
  return useContext(BudgetsContext)
}

export const BudgetsProvider = ({ children }) => {
  const [budgets, setBudgets] = useMongo("budgets", [])
  const [expenses, setExpenses] = useMongo("expenses", [])
  const [monthlyCap, setMonthlyCap] = useMongo("monthlyCap", [])

  function setMonthlyCapTotal(amount) {
    if (amount === 0) {
      return setMonthlyCap([])
    }
    return setMonthlyCap([{"cap": amount}])
  }
  
  function getBudgetExpenses(budgetId) {
    return expenses.filter(expense => expense.budgetId === budgetId)
  }
  
  function addExpense({ description, amount, budgetId }) {
    setExpenses(prevExpenses => {
      return [...prevExpenses, { id: uuidV4(), description, amount, budgetId }]
    })
  }

  function editExpense({ _id, id, description, amount, budgetId }) {
    const expensesData = expenses.slice()    

    expensesData.map((expense, i) => {
      if (expense.id === id) {
        expensesData[i].description = description
        expensesData[i].amount = amount
        expensesData[i].budgetId = budgetId
      }
      setExpenses(expensesData)
    })
  }
  
  function addBudget({ name, max }) {
    setBudgets(prevBudgets => {
      if (prevBudgets.find(budget => budget.name === name)) {
        return prevBudgets
      }
      return [...prevBudgets, { id: uuidV4(), name, max }]
    })
  }

  function editBudget({ _id, id, name, max }) {
    const budgetsData = budgets.slice()    

    budgetsData.map((budget, i) => {
      if (budget.id === id) {
        budgetsData[i].name = name
        budgetsData[i].max = max
      }
      setBudgets(budgetsData)
    })
  }
  
  function deleteBudget({ id }) {
    setExpenses(prevExpenses => {
      return prevExpenses.map(expense => {
        if (expense.budgetId !== id) return expense
        return { ...expense, budgetId: UNCATEGORIZED_BUDGET_ID }
      })
    })

    setBudgets(prevBudgets => {
      return prevBudgets.filter(budget => budget.id !== id)
    })
  }
  
  function deleteExpense({ id }) {
    setExpenses(prevExpenses => {
      return prevExpenses.filter(expense => expense.id !== id)
    })
  }

  return (
    <BudgetsContext.Provider
      value={{
        budgets,
        expenses,
        monthlyCap,
        getBudgetExpenses,
        addExpense,
        addBudget,
        editExpense,
        editBudget,
        deleteBudget,
        deleteExpense,
        setMonthlyCapTotal,
      }}
    >
      {children}
    </BudgetsContext.Provider>
  )
}
