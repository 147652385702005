import { Modal, Button, Stack } from "react-bootstrap"
import { UNCATEGORIZED_BUDGET_ID, useBudgets } from "../contexts/BudgetsContext"
import { currencyFormatter } from "../utils"
import { useState } from "react"
import EditBudgetModal from "./EditBudgetModal"
import EditExpenseModal from "./EditExpenseModal"

export default function ViewExpensesModal({ budgetId, handleClose }) {
  const { getBudgetExpenses, budgets, deleteBudget, deleteExpense, editExpense, editBudget } =
    useBudgets()

  const expenses = getBudgetExpenses(budgetId)
  const budget =
    UNCATEGORIZED_BUDGET_ID === budgetId
      ? { name: "Uncategorized", id: UNCATEGORIZED_BUDGET_ID }
      : budgets.find(b => b.id === budgetId)


  const [showEditBudgetModal, setShowEditBudgetModal] = useState(false)
  const [showEditExpenseModal, setShowEditExpenseModal] = useState(false)
  const [editExpenseData, setEditExpenseData] = useState({})
  const [editBudgetData, setEditBudgetData] = useState({})

  return (
    <>
    <Modal show={budgetId != null} onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title>
          <Stack direction="horizontal" gap="2">
            <div>Expenses - {budget?.name}</div>
            {budgetId !== UNCATEGORIZED_BUDGET_ID && (
              <>
                <Button
                  onClick={() => {
                    setEditBudgetData(budget)
                    setShowEditBudgetModal(budget)
                  }}
                  variant="outline-primary"
                >
                  Edit
                </Button>
                <Button
                  onClick={() => {
                    deleteBudget(budget)
                    handleClose()
                  } }
                  variant="outline-danger"
                >
                  Delete
                </Button>
              </>
            )}
          </Stack>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Stack direction="vertical" gap="3">
          {expenses.map(expense => (
            <Stack direction="horizontal" gap="2" key={expense.id}>
              <div className="me-auto fs-4">{expense.description}</div>
              <div className="fs-5">
                {currencyFormatter.format(expense.amount)}
              </div>
              <Button
                onClick={() => {
                  setEditExpenseData(expense) 
                  setShowEditExpenseModal(expense)
                }}
                size="sm"
                variant="outline-primary"
              >
                &#9999;
              </Button>
              <Button
                onClick={() => deleteExpense(expense)}
                size="sm"
                variant="outline-danger"
              >
                &times;
              </Button>
            </Stack>
          ))}
        </Stack>
      </Modal.Body>
    </Modal>
    <EditBudgetModal
        show={showEditBudgetModal}
        budgetToEdit={editBudgetData}
        handleClose={() => setShowEditBudgetModal(false)} />
    <EditExpenseModal
        show={showEditExpenseModal}
        expenseToEdit={editExpenseData}
        handleClose={() => setShowEditExpenseModal(false)} />
    </>
  )
}
