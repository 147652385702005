import { useBudgets } from "../contexts/BudgetsContext"
import BudgetCard from "./BudgetCard"

export default function CapBudgetCard() {
  const { expenses, monthlyCap } = useBudgets()
  const amount = expenses.reduce((total, expense) => total + expense.amount, 0)

  const cap =  monthlyCap.length > 0 && monthlyCap[0].cap != '0' ? monthlyCap[0].cap : null

  const diff = cap !== null ? cap - amount : 0

  if (cap === null) return null

  return <BudgetCard amount={diff} name="$ Left" gray cap={cap} hideButtons />
}
