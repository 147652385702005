import { UNCATEGORIZED_BUDGET_ID, useBudgets } from "../contexts/BudgetsContext"
import BudgetCard from "./BudgetCard"

export default function UncategorizedBudgetCard(props) {
  const { getBudgetExpenses, monthlyCap, budgets } = useBudgets()
  const amount = getBudgetExpenses(UNCATEGORIZED_BUDGET_ID).reduce(
    (total, expense) => total + expense.amount,
    0
  )

  const cap =  monthlyCap.length > 0 && monthlyCap[0].cap != '0' ? monthlyCap[0].cap : null

  const total =  budgets.reduce((total, budget) => total + budget.max, 0)

  const max = cap !== null ? cap -total : total 


  if (cap === null && amount === 0 ) return null

  return <BudgetCard amount={amount} max={max} name="Uncategorized" gray {...props} />
}
